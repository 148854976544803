import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  const handleLogin = () => {
    window.location.href = "https://app.plan-now.com/login";
  };

  return (
    <div className="App">
      <header className="App-header">
        <button onClick={handleLogin}>Login</button>
      </header>
    </div>
  );
}

export default App;
